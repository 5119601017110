/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.storyblok.com/"
  }, "헤드리스 CMS 개발 회사 storyblok")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.storyblok.com/jobs#open-positions"
  }, "storyblock 채용")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://learnwitheunjae.dev/"
  }, "이은재 님의 시나브로 자바스크립트 강의")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://inf.run/MJSvc"
  }, "이은재 님의 시나브로 자바스크립트 강의(인프런 버전)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vitejs.dev/"
  }, "Vite")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vitest.dev/"
  }, "Vitest")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/conf/infcon-2024/"
  }, "인프콘 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.iros.go.kr/PMainJ.jsp"
  }, "Active X 설치를 요구하는 인터넷 등기소")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
